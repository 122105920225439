
body {
    --bs-body-font-family: Palatino, Verdana, Tahoma, Arial, sans-serif;
    --bs-body-line-height: 1.5;
    --bs-body-bg: #A9D0F5;
    --bs-body-color: #000B12;
    clear: both;
}

.App {
    margin: 0 auto;
    width: 70%;
    display: flex;
    flex-direction: column;
}

.App-header img {
    width: 40%;
    height: auto;
    margin: 1% 0;
}
